import { Stack } from '@mui/material'

import Benefits from './_components/Benefits'
import Cta from './_components/CTA'
import Faq from './_components/FAQ'
import Hero from './_components/Hero'
import Hiring from './_components/Hiring'
import LegalPolicy from './_components/LegalPolicy'
import Products from './_components/Products'
import Reviews from './_components/Reviews'

function LandingPage(): React.JSX.Element {
  return (
    <Stack width="100%" height="auto">
      <Hero />
      <Products />
      <Benefits />
      <LegalPolicy />
      <Hiring />
      <Cta />
      <Reviews />
      <Faq />
    </Stack>
  )
}

export default LandingPage
