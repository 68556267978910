import { Box, Button, Container, Typography } from '@mui/material'
import TextWithLogoReplaced from '@UI/sharedComponents/TextWithLogoReplaced'

import { useTranslations } from 'next-intl'

import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'

const { useRouter } = getLivSharedPathnamesNavigation()

function CTA(): React.JSX.Element {
  const router = useRouter()
  const t = useTranslations('LandingPage.HomePage.CTA')

  return (
    <Box
      sx={{
        backgroundColor: '#EEF8FA',
        py: 8,
      }}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h4"
          sx={{ color: '#2D3748', mb: 3, textAlign: 'center' }}
        >
          {t('Title')}
        </Typography>
        <TextWithLogoReplaced
          text={t('Subtitle')}
          textAlign="center"
          fontSize="20px"
          color="#646E73"
          mb={3}
        />
        <Button
          variant="contained"
          onClick={() => router.push('/user/sign-up/')}
        >
          {t('Button')}
        </Button>
      </Container>
    </Box>
  )
}

export default CTA
