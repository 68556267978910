import { useState } from 'react'
import { Box, Button, Container, Tab, Tabs, Typography } from '@mui/material'
import TextWithLogoReplaced from '@UI/sharedComponents/TextWithLogoReplaced'

import Image from 'next/image'
import { useTranslations } from 'next-intl'

import { useIsMobile } from '@liv-frontend/ui'

import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'

type Props = {
  children?: React.ReactNode
  index: number
  value: number
}

const { useRouter } = getLivSharedPathnamesNavigation()

function CustomTabPanel(props: Props): React.JSX.Element {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index.toString()}`}
      aria-labelledby={`simple-tab-${index.toString()}`}
      {...other}
    >
      {value === index && <Box sx={{ mt: 5, mb: 4 }}>{children}</Box>}
    </div>
  )
}

function Benefits(): React.JSX.Element {
  const isMobile = useIsMobile()
  const router = useRouter()
  const t = useTranslations('LandingPage.HomePage.Benefits')
  const [value, setValue] = useState(0)

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: number,
  ): void => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%', backgroundColor: 'white' }}>
      <Container sx={{ py: 4 }}>
        <Typography variant="h3" sx={{ textAlign: 'center', mb: 2 }}>
          {t('Title')}
        </Typography>
        <Box sx={{ width: '100%', bgcolor: 'transparent' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            centered={!isMobile}
            variant={isMobile ? 'scrollable' : 'standard'}
            scrollButtons={isMobile}
            allowScrollButtonsMobile={isMobile}
            sx={{ display: 'flex' }}
          >
            <Tab
              label={t('Benefit1.Label')}
              sx={{ mr: isMobile ? 0 : 9, textTransform: 'none' }}
            />
            <Tab
              label={t('Benefit2.Label')}
              sx={{ mr: isMobile ? 0 : 9, textTransform: 'none' }}
            />
            <Tab label={t('Benefit3.Label')} sx={{ textTransform: 'none' }} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: isMobile ? 'column-reverse' : 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: isMobile ? '22px' : '67px',
            }}
          >
            <Box>
              <Typography
                variant="h4"
                sx={{
                  mb: '22px',
                  color: '#2D3748',
                  fontSize: ['25px', '25px', '36px'],
                }}
              >
                {t('Benefit1.Title')}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: '#646E73',
                  fontSize: ['15px', '15px', '20px'],
                  mb: '22px',
                }}
              >
                {t('Benefit1.Description')}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 700,
                  mb: '22px',
                }}
              >
                {t('Benefit1.Description2')}
              </Typography>
              <Button
                variant="contained"
                fullWidth={isMobile}
                onClick={() => router.push('/user/sign-up')}
                sx={{ textTransform: 'none', width: 190 }}
              >
                {t('CTA')}
              </Button>
            </Box>
            <Image
              src="/assets/images/landing/benefits3.png"
              alt="legal policy Benefit"
              width={592}
              height={395}
              priority
              loading="eager"
              quality={100}
              style={{
                color: 'transparent',
                width: '100%',
                minWidth: isMobile ? 'auto' : '500px',
                height: 'auto',
                borderRadius: '16px',
              }}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: isMobile ? 'column-reverse' : 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: isMobile ? '22px' : '67px',
            }}
          >
            <Box>
              <Typography
                variant="h4"
                sx={{
                  mb: '22px',
                  color: '#2D3748',
                  fontSize: ['25px', '25px', '36px'],
                }}
              >
                {t('Benefit2.Title')}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: '#646E73',
                  fontSize: ['15px', '15px', '20px'],
                  mb: '22px',
                }}
              >
                {t('Benefit2.Description')}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 700,
                  mb: '22px',
                }}
              >
                {t('Benefit2.Description2')}
              </Typography>
              <Button
                variant="contained"
                fullWidth={isMobile}
                onClick={() => router.push('/user/sign-up')}
                sx={{ textTransform: 'none', width: 190 }}
              >
                {t('CTA')}
              </Button>
            </Box>
            <Image
              src="/assets/images/landing/benefits1-1.png"
              alt="legal policy Benefit"
              width={592}
              height={395}
              priority
              loading="eager"
              quality={100}
              style={{
                color: 'transparent',
                width: '100%',
                minWidth: isMobile ? 'auto' : '500px',
                height: 'auto',
                borderRadius: '16px',
              }}
            />
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: isMobile ? 'column-reverse' : 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: isMobile ? '22px' : '67px',
            }}
          >
            <Box>
              <Typography
                variant="h4"
                sx={{
                  mb: '22px',
                  color: '#2D3748',
                  fontSize: ['25px', '25px', '36px'],
                }}
              >
                {t('Benefit3.Title')}
              </Typography>
              <TextWithLogoReplaced
                text={t('Benefit3.Description')}
                variant="subtitle1"
                color="#646E73"
                fontSize={['15px', '15px', '20px']}
                mb="22px"
              />
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 700,
                  mb: '22px',
                }}
              >
                {t('Benefit3.Description2')}
              </Typography>
              <Button
                variant="contained"
                fullWidth={isMobile}
                onClick={() => router.push('/user/sign-up')}
                sx={{ textTransform: 'none', width: 190 }}
              >
                {t('CTA')}
              </Button>
            </Box>
            <Image
              src="/assets/images/landing/benefits2.png"
              alt="legal policy Benefit"
              width={592}
              height={395}
              priority
              loading="eager"
              quality={100}
              style={{
                color: 'transparent',
                width: '100%',
                minWidth: isMobile ? 'auto' : '500px',
                height: 'auto',
                borderRadius: '16px',
              }}
            />
          </Box>
        </CustomTabPanel>
      </Container>
    </Box>
  )
}

export default Benefits
