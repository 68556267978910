import { Box, Container } from '@mui/material'

import { useIsMobile } from '@liv-frontend/ui'

import InvestigationPackages from '@components/InvestigationPackages'

function LegalPolicy(): React.JSX.Element {
  const isMobile = useIsMobile()

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#EEF8FA',
        pt: '44px',
        pb: 10,
        px: isMobile ? '14px' : 10,
      }}
    >
      <Container>
        <InvestigationPackages />
      </Container>
    </Box>
  )
}

export default LegalPolicy
