import { Box, Card, Container, Typography, useTheme } from '@mui/material'
import TextWithLogoReplaced from '@UI/sharedComponents/TextWithLogoReplaced'

import Image from 'next/image'
import { useTranslations } from 'next-intl'

import { useIsMobile } from '@liv-frontend/ui'

function Hiring(): React.JSX.Element {
  const isMobile = useIsMobile()
  const theme = useTheme()
  const t = useTranslations('LandingPage.ContractPage.Hiring')

  return (
    <Box sx={{ background: 'white', py: 4 }}>
      <Container sx={{ py: 4 }}>
        <Typography variant="h3" sx={{ textAlign: 'center', mb: 2 }}>
          {t('Title')}
        </Typography>
        <TextWithLogoReplaced
          text={t('Subtitle')}
          textAlign="center"
          fontSize="20px"
          color="#646E73"
          mb={3}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row ',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box width="100%">
            <Card
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80px',
                height: '80px',
                textAlign: 'center',
                mb: 4,
                boxShadow:
                  '0px 10px 30px rgba(37, 169, 190, 0.3), 0px 10px 20px rgba(37, 169, 190, 0.2)',
              }}
            >
              <Typography variant="h6" color={theme.palette.secondary.main}>
                1
              </Typography>
            </Card>
            <TextWithLogoReplaced text={t('Step1')} variant="h6" mb={3} />
          </Box>
          <Image
            src="/assets/images/landing/products/contracts/step1.svg"
            alt="contracts product"
            width={317}
            height={355}
            style={{ objectFit: 'contain' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row ',
            gap: isMobile ? 0 : 11,
            alignItems: 'center',
          }}
        >
          <Image
            src="/assets/images/landing/products/contracts/step2.svg"
            alt="contracts product"
            width={347}
            height={355}
            style={{
              objectFit: 'contain',
              order: isMobile ? 2 : 1,
            }}
          />
          <Box order={isMobile ? 1 : 2} width="100%">
            <Card
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80px',
                height: '80px',
                textAlign: 'center',
                mb: isMobile ? 2 : 4,
                boxShadow:
                  '0px 10px 30px rgba(37, 169, 190, 0.3), 0px 10px 20px rgba(37, 169, 190, 0.2)',
              }}
            >
              <Typography variant="h6" color={theme.palette.secondary.main}>
                2
              </Typography>
            </Card>
            <Typography variant="h6" mb={3} maxWidth="543px">
              {t('Step2')}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row ',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box width="100%">
            <Card
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80px',
                height: '80px',
                textAlign: 'center',
                mb: 4,
                boxShadow:
                  '0px 10px 30px rgba(37, 169, 190, 0.3), 0px 10px 20px rgba(37, 169, 190, 0.2)',
              }}
            >
              <Typography variant="h6" color={theme.palette.secondary.main}>
                3
              </Typography>
            </Card>
            <Typography variant="h6" mb={2} maxWidth="543px">
              {t('Step3')}
            </Typography>
          </Box>
          <Image
            src="/assets/images/landing/products/contracts/step3.svg"
            alt="investigations product"
            width={361}
            height={355}
            style={{ objectFit: 'contain' }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row ',
            gap: isMobile ? 0 : 11,
            alignItems: 'center',
          }}
        >
          <Image
            src="/assets/images/landing/products/contracts/step4.svg"
            alt="investigations product"
            width={347}
            height={336}
            style={{
              objectFit: 'contain',
              order: isMobile ? 2 : 1,
            }}
          />
          <Box order={isMobile ? 1 : 2} width="100%">
            <Card
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80px',
                height: '80px',
                textAlign: 'center',
                mb: isMobile ? 2 : 4,
                boxShadow:
                  '0px 10px 30px rgba(37, 169, 190, 0.3), 0px 10px 20px rgba(37, 169, 190, 0.2)',
              }}
            >
              <Typography variant="h6" color={theme.palette.secondary.main}>
                4
              </Typography>
            </Card>
            <Typography variant="h6" mb={2} maxWidth="543px">
              {t('Step4')}
            </Typography>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Hiring
