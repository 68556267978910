import { useState } from 'react'
import { PaidOutlined } from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grow,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import PriceNoDecimalsThousandsSeparatorMask from '@UI/sharedComponents/InputMasks/PriceNoDecimalsThousandsSeparatorMask'
import TextWithLogoReplaced from '@UI/sharedComponents/TextWithLogoReplaced'

import { useFormatter, useTranslations } from 'next-intl'

import { useIsMobile } from '@liv-frontend/ui'

import { getCost } from '@components/Calculator/Calculator'
import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'

const { useRouter } = getLivSharedPathnamesNavigation()

function InvestigationPackages(): React.JSX.Element {
  const isMobile = useIsMobile()
  const theme = useTheme()
  const router = useRouter()
  const t = useTranslations('LandingPage.HomePage.LegalPolicy')
  const tSharedLanding = useTranslations('LandingPage.SharedLanding')
  const formatter = useFormatter()
  const [rentValue, setRentValue] = useState(0)
  const [calculatedPrice, setCalculatedPrice] = useState(0)
  const BASE_INVESTIGATIONS_PRICE = 1000

  const mock = [
    {
      id: 1,
      title: tSharedLanding('Package1.Type'),
      subtitle: tSharedLanding('Package1.Subtype'),
      price: tSharedLanding('Package1.Price'),
      featuresIncluded: [
        tSharedLanding('Include.1'),
        tSharedLanding('Include.2'),
        tSharedLanding('Include.3'),
        tSharedLanding('Include.4'),
        tSharedLanding('Include.5'),
      ],
      isHighlighted: false,
      moreInfoUrl: '/products/legal-policy/',
    },
    {
      id: 2,
      title: tSharedLanding('Package2.Type'),
      subtitle: tSharedLanding('Package2.Subtype'),
      price: '1,800',
      featuresIncluded: [
        tSharedLanding('Include.1'),
        tSharedLanding('Include.2'),
        tSharedLanding('Include.3'),
        tSharedLanding('Include.4'),
      ],
      isHighlighted: false,
      moreInfoUrl: '/products/contracts/',
    },
    {
      id: 3,
      title: tSharedLanding('Package3.Type'),
      subtitle: tSharedLanding('Package3.Subtype'),
      price: '500',
      featuresIncluded: [
        tSharedLanding('Include.6'),
        tSharedLanding('Include.7'),
        tSharedLanding('Include.8'),
        tSharedLanding('Include.9'),
        tSharedLanding('Include.10'),
        tSharedLanding('Include.11'),
        tSharedLanding('Include.12'),
      ],
      isHighlighted: false,
      moreInfoUrl: '/products/investigations/',
    },
  ]

  const handleCalculate = (): void => {
    if (!rentValue) return
    const cost = getCost(rentValue) + BASE_INVESTIGATIONS_PRICE // adding the cost of the investigations
    setCalculatedPrice(cost)
  }
  return (
    <>
      <Typography
        variant="h3"
        textAlign="center"
        marginTop={4}
        marginBottom={4}
      >
        {t('Title2')}
      </Typography>
      <Grid container justifyContent="center" spacing={4} paddingTop={2}>
        {mock.map((item, index) => (
          <Grid size={{ xs: 12, md: 4 }} key={item.title}>
            <Box
              component={Card}
              height={1}
              display="flex"
              flexDirection="column"
              boxShadow="0px 4px 24px 0px #00000040"
              border={
                item.isHighlighted
                  ? `1px solid ${theme.palette.primary.main}`
                  : '1px solid #D6D6D6'
              }
            >
              <CardContent sx={{ px: 3, pt: 3, pb: 0 }}>
                <Box
                  mb={1}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <TextWithLogoReplaced
                    text={item.title}
                    textAlign="center"
                    color="#2D3748"
                    fontSize="20px"
                    fontWeight={700}
                  />
                  <Typography
                    sx={{
                      color: '#A9ABAE',
                      textAlign: 'center',
                      fontSize: '14px',
                      fontWeight: 700,
                    }}
                  >
                    {/* eslint-disable-next-line no-nested-ternary --- mantaining ternary */}
                    {index !== 0
                      ? item.subtitle
                      : calculatedPrice
                        ? tSharedLanding('Package1.Price')
                        : tSharedLanding('Package1.PriceFrom')}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(index === 0 &&
                        calculatedPrice && {
                        border: `1px solid ${theme.palette.primary.main}`,
                        backgroundColor: theme.palette.primary.light,
                        borderRadius: '42px',
                        padding: '0 16px',
                        mt: 1,
                      }),
                    }}
                  >
                    <Typography
                      variant="h2"
                      color={item.id === 3 ? 'secondary' : 'primary'}
                      sx={{
                        fontSize: isMobile ? '45px' : '60px',
                        fontWeight: 700,
                      }}
                    >
                      {item.id === 1
                        ? formatter.number(calculatedPrice || 3500, 'currency')
                        : `$${item.price}`}
                    </Typography>
                  </Box>
                </Box>
                <Grid container spacing={1}>
                  {item.featuresIncluded.map((feature) => (
                    <Grid size={{ xs: 12 }} key={`${item.title}-${feature}`}>
                      <Box
                        component={ListItem}
                        disableGutters
                        width="auto"
                        padding={0}
                      >
                        <Box
                          component={ListItemAvatar}
                          minWidth="auto !important"
                          marginRight={2}
                        >
                          <Box
                            component={Avatar}
                            bgcolor={
                              item.id !== 3
                                ? theme.palette.primary.main
                                : theme.palette.secondary.main
                            }
                            width={24}
                            height={24}
                          >
                            <svg
                              width={20}
                              height={20}
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </Box>
                        </Box>
                        <ListItemText primary={feature} />
                      </Box>
                    </Grid>
                  ))}
                  {/* {item.featuresNotIncluded?.map((feature) => (
                    <Grid size={{ xs:12}} key={`${item.title}-${feature}`}>
                      <Box
                        component={ListItem}
                        disableGutters
                        width="auto"
                        padding={0}
                      >
                        <Box
                          component={ListItemAvatar}
                          minWidth="auto !important"
                          marginRight={2}
                        >
                          <Box
                            component={Avatar}
                            bgcolor="#0000001F"
                            width={24}
                            height={24}
                          >
                            <Close
                              sx={{
                                width: '20px',
                                height: '20px',
                                color: '#FFFFFFDE',
                              }}
                            />
                          </Box>
                        </Box>
                        <ListItemText
                          primary={feature}
                          sx={{
                            color: theme.palette.text.disabled,
                            textDecorationLine: 'line-through',
                          }}
                        />
                      </Box>
                    </Grid>
                  ))} */}
                </Grid>
                {item.id === 1 ? (
                  <Grid container spacing={1}>
                    <Box
                      mb={1}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      width="100%"
                      gap={2}
                    >
                      <Typography
                        sx={{
                          textAlign: 'center',
                          color: '#2D3748',
                          fontSize: '20px',
                          fontWeight: 700,
                          mt: 5,
                        }}
                      >
                        {tSharedLanding('Quote')}
                      </Typography>
                      <Grow in={Boolean(calculatedPrice)} timeout={500}>
                        <Box
                          display="flex"
                          alignItems="center"
                          gap={1}
                          id="quoteMobile"
                        >
                          <Typography variant="h6" fontWeight={500}>
                            {tSharedLanding('YourPolicy')}
                          </Typography>
                          <Typography
                            color="primary"
                            sx={{
                              fontSize: '30px',
                              fontWeight: 700,
                            }}
                          >
                            {formatter.number(
                              calculatedPrice || 3500,
                              'currency',
                            )}
                          </Typography>
                        </Box>
                      </Grow>

                      <TextField
                        type="number"
                        label={tSharedLanding('Package1.InputText')}
                        fullWidth
                        onChange={(event) => {
                          const { value } = event.target
                          setRentValue(Number(value))
                        }}
                        InputProps={{
                          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Same as calculator
                          inputComponent:
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Same as calculator
                            PriceNoDecimalsThousandsSeparatorMask as any,
                        }}
                      />
                      <Button
                        variant="contained"
                        endIcon={<PaidOutlined />}
                        onClick={handleCalculate}
                        size="large"
                        fullWidth={isMobile}
                        sx={{ height: '56px', width: '100%' }}
                      >
                        {tSharedLanding('Package1.ButtonText')}
                      </Button>
                    </Box>
                  </Grid>
                ) : null}
              </CardContent>
              <Box flexGrow={1} />
              <CardActions
                sx={{
                  justifyContent: isMobile ? 'flex-end' : 'center',
                  p: 3,
                }}
              >
                <Button
                  variant="text"
                  fullWidth={!isMobile}
                  onClick={() => router.push(item.moreInfoUrl)}
                  color={item.id === 3 ? 'secondary' : 'primary'}
                >
                  {tSharedLanding('MoreInformation')}
                </Button>
              </CardActions>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default InvestigationPackages
